import http from '@/utils/http';

/**
 * 提现管理
 */
export default {
  getList: {
    p: 'post,/manage/withdraw/getlist',
    r: (data) => http({ url: '/manage/withdraw/getlist', method: 'post', data })
  },
  update: {
    p: 'post,/manage/withdraw/update',
    r: (data) => http({ url: '/manage/withdraw/update', method: 'post', data })
  },
  del: {
    p: 'post,/manage/withdraw/delete',
    r: (data) => http({ url: '/manage/withdraw/delete', method: 'post', data })
  },
  getOne: {
    p: 'post,/manage/withdraw/getone',
    r: (data) => http({ url: '/manage/withdraw/getone', method: 'post', data })
  },
  exportFile: {
    p: 'post,/manage/withdraw/export',
    r: (data) => http({ url: '/manage/withdraw/export', method: 'post', data })
  }
};
