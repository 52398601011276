import './list.scss';
import dataApi from '@/api/manage/withdraw.js';
import dataMemberApi from '@/api/manage/member.js';

const dataStatus = [
  {
    label: '已作废',
    value: 0,
    disabled: false
  },
  {
    label: '待审核',
    value: 1,
    disabled: false
  },
  {
    label: '驳回申请',
    value: 2,
    disabled: false
  },
  {
    label: '同意申请',
    value: 3,
    disabled: false
  },
  {
    label: '发放中',
    value: 4,
    disabled: false
  },
  {
    label: '完结',
    value: 5,
    disabled: false
  }
];
const dataType = [
  {
    label: '交易奖金',
    value: 'balance',
    disabled: false
  },
  {
    label: '团队奖金',
    value: 'team',
    disabled: false
  }
];

export default {
  name: 'withdraw-list',
  components: {},
  data () {
    return {
      page: {
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 100]
      },
      dialogVisible: false,
      bankList: [],
      productList: [],
      tableData: [],
      tbleOption: {
        total: 10,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        ...this.$store.getters.tableConfig,
        addBtn: false,
        selection: false,
        delBtn: false,
        editBtn: false,
        column: [
          {
            label: '收款人',
            prop: 'member',
            editDisplay: false
          },
          {
            label: '流水号',
            prop: 'batch',
            type: 'input',
            disabled: true,
            addDisplay: 'true',
            editDisplay: 'true'
          },
          {
            label: '提现金额',
            prop: 'amount',
            type: 'input',
            disabled: true,
            addDisplay: 'true',
            editDisplay: 'true'
          },
          {
            label: '个人所得税',
            prop: 'tax_amount',
            type: 'input',
            addDisplay: 'true',
            editDisplay: 'true'
          },
          {
            label: '提现手续费',
            prop: 'charge',
            type: 'input',
            addDisplay: 'true',
            editDisplay: 'true'
          },
          {
            label: '实际到账',
            prop: 'real_amount',
            type: 'input',
            addDisplay: 'true',
            editDisplay: 'true'
          },
          {
            type: 'select',
            label: '类型',
            value: '',
            dicData: dataType,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'type'
          },
          {
            label: '状态',
            type: 'radio',
            value: 0,
            dicData: dataStatus,
            prop: 'status'
          },
          {
            // 创建者 create_by
            label: '创建者',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_user'
          },
          {
            // 创建时间 create_time
            label: '创建时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          },
          {
            // 更新者 update_by
            label: '更新者',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_user'
          },
          {
            // 更新时间 update_time
            label: '更新时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_time'
          }
        ]
      },
      formDa: {
        bank: {
          account: '',
          bank: '',
          bank_no: ''
        }
      },
      formOp: {
        column: [
          {
            label: '交易流水号',
            span: 24,
            disabled: true,
            prop: 'batch'
          },
          {
            label: '收款人',
            span: 12,
            disabled: true,
            prop: 'guest',
            bind: 'member.nickname'
          },
          {
            type: 'radio',
            label: '奖金类型',
            dicData: [{
              label: '交易佣金',
              value: 'balance',
              disabled: true
            }, {
              label: '团队奖',
              value: 'team',
              disabled: true
            }],
            span: 12,
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'type'
          },
          {
            type: 'input',
            label: '提现金额',
            span: 12,
            display: true,
            disabled: true,
            prop: 'amount',
            append: '元'
          },
          {
            type: 'input',
            label: '提现手续费',
            span: 12,
            display: true,
            disabled: true,
            prop: 'charge',
            append: '元'
          },
          {
            type: 'input',
            label: '个税金额',
            span: 12,
            display: true,
            disabled: true,
            prop: 'tax_amount',
            append: '元'
          },
          {
            type: 'input',
            label: '实到金额',
            span: 12,
            display: true,
            disabled: true,
            prop: 'real_amount',
            append: '元'
          },
          {
            label: '银行卡',
            prop: 'bank_id',
            span: 24,
            disabled: true,
            formslot: true
          },
          {
            type: 'radio',
            label: '状态',
            value: 1,
            dicData: dataStatus,
            span: 24,
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'status',
            required: true,
            rules: [
              {
                required: true,
                message: '请选择状态'
              }
            ]
          },
          {
            type: 'textarea',
            label: '说明',
            span: 24,
            display: true,
            prop: 'remark',
            maxlength: 500,
            minRows: 3,
            maxRows: 6,
            showWordLimit: true
          }
        ],
        disabled: false,
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 110,
        gutter: 0,
        menuBtn: true,
        submitBtn: true,
        submitText: '提交',
        emptyBtn: false,
        emptyText: '清空',
        menuPosition: 'center',
        tabs: false
      },
      searchOption: {
        column: [
          {
            label: '受益人',
            prop: 'member_id',
            type: 'table',
            span: 8,
            dialogWidth: '60%',
            dataType: 'number',
            children: {
              border: true,
              column: [
                {
                  label: '编号',
                  prop: 'id',
                  width: 80
                },
                {
                  label: '会员名称',
                  search: true,
                  searchSpan: 24,
                  prop: 'nickname',
                  align: 'left'
                }
              ]
            },
            formatter: (row) => {
              if (!row.nickname) return '';
              return `${row.nickname}`;
            },
            onLoad: ({ page, value, data }, callback) => {
              // 首次加载去查询对应的值
              if (value) {
                const postData = {
                  id: value
                };
                dataMemberApi.getOne.r(postData).then(res => {
                  callback({
                    ...res.data
                  });
                });
                return;
              }
              console.log(page, value, data);
              const postData = {
                page: page.currentPage,
                pageSize: page.pageSize,
                ...data
              };
              dataMemberApi.getList.r(postData).then(res => {
                callback({
                  total: res.data.total,
                  data: res.data.list
                });
              });
            },
            props: {
              label: 'nickname',
              value: 'id'
            }
          },
          {
            type: 'select',
            label: '状态',
            value: '',
            dicData: dataStatus,
            span: 8,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'status'
          },
          {
            type: 'select',
            label: '类型',
            value: '',
            dicData: dataType,
            span: 8,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'type'
          },
          {
            type: 'daterange',
            label: '日期范围',
            span: 8,
            display: true,
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            prop: 'datetime'
          }
        ],
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 110,
        gutter: 0,
        menuBtn: true,
        submitBtn: true,
        submitText: '搜索',
        emptyBtn: true,
        emptyText: '清空',
        menuPosition: 'center',
        tabs: false
      },
      searchData: {}
    };
  },
  computed: {},
  mounted () {
  },
  filters: {},
  methods: {
    // 分页
    onLoad (page) {
      this.page = page;
      this.getList();
    },
    // 筛选
    searchChange (params, done) {
      // 处理日期
      const { datetime } = params;
      if (datetime) {
        // eslint-disable-next-line prefer-destructuring
        params.starttime = datetime[0];
        // eslint-disable-next-line prefer-destructuring
        params.endtime = datetime[1];
      }
      delete params.datetime;
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset () {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    searchExcel () {
      const postData = {
        ...this.searchData
      };
      dataApi.exportFile.r(postData).then(res => {
        this.$confirm('是否下载导出数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: false
        }).then(() => {
          window.location.href = res.data.file;
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消下载'
          });
        });
      });
    },
    dialogCloseCallback () {
      this.formDa = {
        bank: {
          account: '',
          bank: '',
          bank_no: ''
        }
      };
      this.dialogVisible = false;
      this.$refs.form.resetForm();
    },
    rowView (row) {
      this.getInfo(row);
      this.dialogVisible = true;
      this.formOp.disabled = true;
      this.formOp.submitBtn = false;
    },
    addDialogForm (row) {
      if (row.id !== undefined) {
        this.getInfo(row);
      }
      this.dialogVisible = true;
    },
    // 修改
    rowUpdate (form, done) {
      if (form.status > 5) {
        this.$message.error('状态标记有误~');
        return false;
      }
      dataApi.update.r(form).then(() => {
        this.$refs.form.resetForm();
        this.getList();
        this.dialogVisible = false;
      });
      done();
    },
    getInfo (row) {
      dataApi.getOne.r({ id: row.id }).then(res => {
        this.formDa = res.data;
        // 设置可选状态
        for (let i = 0; i < dataStatus.length - 1; i++) {
          if (res.data.status > i) {
            dataStatus[i].disabled = true;
          } else {
            dataStatus[i].disabled = false;
          }
        }
        // 设置是否可编辑
        if (res.data.status === 5) {
          this.formOp.disabled = true;
          this.formOp.submitBtn = false;
        }
      });
    },
    // 获取
    getList () {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then(res => {
        console.log('接口返回', res);
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    }
  }
};
